import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import ImageModalView from '../../components/ImageModalView';
import Paginator from '../../components/Paginator';

import Request from '../../core/http';

const Tabla = () => {
	const [origin, setOrigin] = useState([]);
	const [filas, setFilas] = useState([]);
	const [elements, setElements] = useState([]);
	const [viewImage, setViewImage] = useState('');
	const [isOpenViewImage, setIsOpenViewImage] = useState(false);
	const [searchQR, setSearchQR] = useState('');
	const [searchIdFac, setSearchIdFac] = useState('');
	const [editId, setEditId] = useState(0);
	const [newLimit, setNewLimit] = useState(0);

	useEffect(() => {
		loadTable();
	}, [])

	async function loadTable() {
		const response = await Request.get('/aquanicksales');

		if (Array.isArray(response)) {
			setFilas(response);
			setOrigin(response);
		} else {
			setFilas([]);
		}
	}

	useEffect(() => {
		if(searchQR) {
			let _filas = [...origin];
			_filas = _filas.filter(fila => {
				const regexQR = new RegExp(`${searchQR}`, 'gi');
				if(regexQR.test(fila.QR_Fac))
					return true
				else
					return false;
			});
			setFilas([..._filas]);
		}
		if(searchIdFac) {
			let _filas = [...origin];
			_filas = _filas.filter(fila => {
				const regexId = new RegExp(`${searchIdFac}`, 'gi');
				if(regexId.test(fila.ID_Fac))
					return true
				else
					return false;
			});
			setFilas([..._filas]);
		}
	}, [searchQR, searchIdFac]);

	async function saveChanges(data, limit) {
		const response = await Request.post('/aquanicksales/setlimit', {...data, limite_fotos: limit});

		if (Array.isArray(response)) {
			cogoToast.success('Registro actualizado');
			setFilas(response);
			setOrigin(response);
		} else {
			cogoToast.error('No se actualizo el registro');
		}

		setEditId(0);
	}

	return (
		<div className="column table-container">
			<div className='column controls'>
				<div className="input-container">
					<label>
						ID_Fac:
					</label>
					<input
						className='input'
						onChange={(input) => setSearchIdFac(input.target.value)}
						value={searchIdFac}
					/>
				</div>
				<div className="input-container">
					<label>
						QR_Fac:
					</label>
					<input
						className='input'
						onChange={(input) => setSearchQR(input.target.value)}
						value={searchQR}
					/>
				</div>
			</div>
			<table id="tabla-aquanicksales">
				<thead>
					<tr>
						<th className="text-left">ID_Fac</th>
						<th className="text-center">QR_Fac</th>
						<th className="text-center">QR</th>
						<th className="text-center" style={{width: "100px"}}>Limite</th>
						<th className="text-center">Fecha</th>
						<th className="text-center" style={{width: "100px"}}>Editar</th>
						<th></th>
						<th className="text-center" style={{width: "100px"}}>Lista</th>
					</tr>
				</thead>
				<tbody>
					{elements.map((fila) => 
						<tr key={fila.QR_Fac}>
							<td>
								{fila.ID_Fac}
							</td>
							<td>
								{fila.QR_Fac}
							</td>
							<td>
								<img className="pointer" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${fila.QR_Fac}`} alt={`QR_${fila.QR_Fac}`}
									onClick={() => {
										setViewImage(`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${fila.QR_Fac}`);
										setIsOpenViewImage(true);
									}}/>
							</td>
							<td className="text-center">
								{editId == fila.ID_Fac
								? <div className='input-container'>
									<input
										className='input'
										onChange={(input) => setNewLimit(input.target.value)}
										value={newLimit}/>
								</div>
								: fila.limite_fotos
								}
							</td>
							<td>
								{moment(fila.creado).format('DD/MM/YYYY hh:mm a')}
							</td>
							<td>
								{editId == fila.ID_Fac
								? <div className='control' onClick={() => saveChanges(fila, newLimit)}>
									<i className="fa fa-save"></i>
								</div>
								: <div className='control' onClick={() => {
									setEditId(fila.ID_Fac);
									setNewLimit(fila.limite_fotos);
								}}>
									<i className="fa fa-pencil"></i>
								</div>
								}
							</td>
							<td>
								{editId == fila.ID_Fac
								? <div className='control' onClick={() => setEditId(0)}>
									<i className="fa fa-times"></i>
								</div>
								: <div className='control-none'>
								</div>
								}
							</td>
							<td>
								<div className="control" onClick={() => alert(fila?.fotos_seleccionadas)}>
									<i className="fa fa-file"></i>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<Paginator
				elements={filas}
				setElements={(elements) => setElements(elements)}/>
			<ImageModalView
				open	=	{isOpenViewImage}
				imagen	=	{viewImage}
				onClose	=	{() => {
					setIsOpenViewImage(false);
					setViewImage('');
				}}/>
		</div>
	)
}

export default Tabla;
