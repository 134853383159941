import React from 'react';

import { Consumer } from '../../context';

//Styles
import './home.scss';

const Home = ({context}) => {
	return (
		<div className="home">
			<h1>Home</h1>
		</div>
	)	
}

export default Consumer(Home);
