import React from 'react';
import { useHistory } from 'react-router-dom';

import { Consumer } from '../../context';

// import Tabla from './tabla';

//Styles
import './atractivo.scss';

const Atractivo = ({context}) => {

	const history = useHistory();

	return (
		<div className="atractivo page">
			<h1>Fichas Tecnicas</h1>
			<div className="controles">
				<button className="btn" onClick={() => history.push('/atractivo/editar/1')}>
					Editar
				</button>
			</div>
			<div>
				Información del atractivo
			</div>
		</div>
	)	
}

export default Consumer(Atractivo);