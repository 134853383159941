import { useEffect, useState } from "react";
import './styles.scss';

function noFunc() {

};

const Paginator = ({elements = [], setElements = noFunc()}) => {
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(elements) {
            const countPages = Math.ceil(elements.length / 20);
            const numberPages = [];
            for (let index = 1; index <= countPages; index++) {
                numberPages.push(index);
            }
            setPages(numberPages);
            setElements(elements.slice((elements.length * (currentPage - 1)), (currentPage * 7)));
        }
    }, [elements]);

    useEffect(() => {
        setElements(elements.slice(((currentPage - 1) * 7), (currentPage * 7)));
    }, [currentPage]);

    function getStart() {
        if (currentPage < 5) {
            return 0
        } else if (currentPage - 4 >= (pages.length - 7)) {
            return pages.length - 7;
        }else {
            return (currentPage - 4);
        }
    }

    function getEnd() {
        if (currentPage <= 4) {
            return 7
        } else if (currentPage >= pages.length) {
            return pages.length;
        } else {
            return (currentPage + 6);
        }
    }

    return (
        <div className="paginator">
            {pages.slice(getStart(), getEnd()).map(page => {
                return (
                    <div key={page} className={`element ${currentPage === page && 'selected'}`} onClick={() => setCurrentPage(page)}>
                        <h1>{page}</h1>
                    </div>
                )
            })}
        </div>
    )
}

export default Paginator;
