import React, { useEffect, useState } from 'react';

import ImagenUpload from '../UploadFile/Imagen';
import AudioUpload from '../UploadFile/Audio';
import VideoUpload from '../UploadFile/Video';

const basicos =  { titulo: '', dato: ''};

const App360Form = ({especimen, setData, setFiles, mobileImage}) => {
    const [nombre, setNombre] = useState('');
	const [profileImage, setProfileImage] = useState({file: null, uri: "https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png"});
    const [audioInformacion, setAudioInformacion] = useState({file: null, uri: ''});
	const [basicoA, setBasicoA] = useState(basicos);
	const [basicoB, setBasicoB] = useState(basicos);
	const [basicoC, setBasicoC] = useState(basicos);
	const [datosInteresantes, setDatosInteresantes] = useState([]);
	const [imagenes, setImagenes] = useState([]);
	const [videos, setVideos] = useState([]);
	const [audios, setAudios] = useState([]);
    const [selectedCurrent, setSelectedCurrent] = useState({});

    useEffect(() => {
		let datos = [];

		for(let di = 0; di <= 9; di++) {
			datos.push({ id: (di + 1), titulo: '', icono: {file: null, uri: ''}, detalle: ''});
		}

		setDatosInteresantes(datos);

	}, []);

    useEffect(() => {
		if (especimen) {
			setNombre(especimen.nombre);
			setProfileImage({file: null, uri: especimen.foto_perfil || 'https://img.icons8.com/officel/2x/add-image.png'});
			setAudioInformacion({file: null, uri: especimen.audio_informacion || ''});

			let datosBasicos = especimen.datos_basicos,
			dInteresantes = especimen.datos_interesantes,
			iIlustrativas = especimen.imagenes_ilustrativas,
			vTematicos = especimen.videos_tematicos,
			aComplementarios = especimen.audios_complementarios;

			if(especimen.datos_basicos) {
				if (datosBasicos.basicoA) {
					setBasicoA(datosBasicos.basicoA);
				}
				if (datosBasicos.basicoB) {
					setBasicoB(datosBasicos.basicoB);
				}
				if (datosBasicos.basicoC) {
					setBasicoC(datosBasicos.basicoC);
				}
			}

			if (dInteresantes) {
				if(Array.isArray(dInteresantes)) {
					dInteresantes = dInteresantes.map(d => {
						if(d.icono) {
							if (d.icono.uri) {
								d.icono = {file: null, uri: d.icono.uri}
							} else {
								d.icono = {file: null, uri: d.icono}
							}
						} else {
							d.icono = {file: null, uri: ''}
						}
						return d;
					});

					setDatosInteresantes(dInteresantes);
				}
			}

			if(iIlustrativas) {
				if(Array.isArray(iIlustrativas)) {
					iIlustrativas = iIlustrativas.map(i => {
						if(i) {
							i = { file: null, uri: i };
						}
						return i;
					}).filter(i => i);

					setImagenes(iIlustrativas);
				}
			}

			if(vTematicos) {
				if(Array.isArray(vTematicos)) {
					vTematicos = vTematicos.map(v => {
						if(v) {
							v = { file: null, uri: v }
						}
						return v;
					}).filter(v => v);

					setVideos(vTematicos);
				}
			}

			if(aComplementarios) {
				if(Array.isArray(aComplementarios)) {
					aComplementarios = aComplementarios.map(a => {
						if(a) {
							a = { file: null, uri: a };
						}
						return a;
					}).filter(a => a);

					setAudios(aComplementarios);
				}
			}
		}
    }, [especimen])

    useEffect(() => {
        const datos_basicos = JSON.stringify({
            basicoA,
            basicoB,
            basicoC
        });

        let data = {...especimen, nombre, datos_basicos};
        let files = [];

		if (profileImage.file) {
			files.push({name: 'foto_perfil', file: profileImage.file});
		}

		if(audioInformacion.file) {
			files.push({name: 'audio_informacion', file: audioInformacion.file});
		}

        if(datosInteresantes) {
            let datos_interesantes = [];
            for(let index in datosInteresantes) {
                const dato = datosInteresantes[index];

                datos_interesantes.push({
					id: dato.id,
					titulo: dato.titulo,
					icono: dato.icono ? dato.icono.uri : undefined,
					dato: dato.dato
				});

                if(dato.icono.file) {
                    files.push({name: `di_icono_${dato.id}`, file: dato.icono.file})
                }
            }

            data = {...data, datos_interesantes: JSON.stringify(datos_interesantes)};
        }

		if(imagenes.length > 0) {
			let imagenes_ilustrativas = [];
			imagenes.forEach((imagen, index) => {
				if(imagen.file) {
					imagenes_ilustrativas.push(`imagen_ilustrativa_${index}`);
					files.push({name: `imagen_ilustrativa_${index}`, file: imagen.file});
				} else if (imagen.uri.indexOf('http') !== -1) {
					imagenes_ilustrativas.push(imagen.uri);
				}
			});

			data = {...data, imagenes_ilustrativas};
		}

		if(videos.length > 0) {
			let videos_tematicos = [];
			videos.forEach((video, index) => {
				if(video.file) {
					videos_tematicos.push(`video_tematico_${index}`);
					files.push({name: `video_tematico_${index}`, file: video.file});
				} else if (video.uri.indexOf('http') !== -1) {
					videos_tematicos.push(video.uri);
				}
			});

			data = {...data, videos_tematicos};
		}

		if(audios.length > 0) {
			let audios_complementarios = [];
			audios.forEach((audio, index) => {
				if(audio.file) {
					audios_complementarios.push(`audio_complementario_${index}`);
					files.push({name: `audio_complementario_${index}`, file: audio.file});
				} else if (audio.uri.indexOf('http') !== -1) {
					audios_complementarios.push(audio.uri);
				}
			});

			data = {...data, audios_complementarios};
		}

        setData && setData(data);
        setFiles && setFiles(files);

    },[nombre, profileImage, basicoA, basicoB, basicoC, audioInformacion, datosInteresantes, imagenes, videos, audios])

    const matriz = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

    function selectCurrent(selected) {
        let current = {...selectedCurrent};

        if(current[selected]) {
            current[selected] = undefined;
        } else {
            current[selected] = true;
        }

        current = {...current};

        setSelectedCurrent({...current});
    }

    return (
        <>

            <section className="column banner-form">
                <div className="row full">
                    <div className="column img-column">
                        <ImagenUpload
                            id		=	"especimen-foto"
                            setFile	= 	{(imagen) => setProfileImage(imagen)}
                            imagen 	= 	{profileImage}/>
                    </div>
                   <div className="column full justify-end">
                        <h1 className="color-white uppercase">{nombre}</h1>
                   </div>
                </div>
            </section>
        

            <div className="white-space-16"></div>
            <h2>Audio</h2>
            <div className="white-space-24"></div>

            <section>
                <div className="input-content full">
                    <AudioUpload
                        id		=	"especimen-audio"
                        setFile	= 	{(audio) => setAudioInformacion(audio)}
                        audio 	= 	{audioInformacion}/>
                </div>
            </section>
            
            <div className="white-space-16"></div>
            <h2>Plantilla</h2>
            <div className="white-space-24"></div>


            <div className="input-content full" id="plantilla_imagen_360">
                <img src={mobileImage} alt="mobile"/>
                <div className='selectArea'>
                    {matriz.map(element => {
                        return <div key={element} className={`selectElement ${selectedCurrent[element] && 'active'}`} onClick={() => selectCurrent(element)}></div>;
                    })}
                </div>
            </div>


            <div className="white-space-32"></div>
            
            <button type="submit" className="btn btn-ok full">
                Guardar
            </button>
        </>
    )
}

export default App360Form;
