import React from 'react';
import { BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
//Api context
import { Provider } from '../context';

//Componentes
import SideBar from '../components/SideBar';

//Paginas
import Login from '../pages/login';
import Home from '../pages/home';

import Sitios from '../pages/sitios';
import SitiosAgregar from '../pages/sitios/agregar';

import FichasTecnicas from '../pages/fichas_tecnicas';
import FichasTecnicasAgregar from '../pages/fichas_tecnicas/agregar';
import FichasTecnicasEditar from '../pages/fichas_tecnicas/editar';

import Atractivo from '../pages/atractivo';
import AtractivoEditar from '../pages/atractivo/editar';
import NotFound from '../pages/404';
import App360 from '../pages/app360/App360';
import AquanickSales from '../pages/aquanick/Sales';

const Routes = (props) => {
	return (
		<Provider>
			<Router>
				<Switch>
					<Route path="/" exact component={Login} />
					<Route path="/login" exact component={Login} />
					<SideBar>
						<Route path="/home" exact component={Home} />
						<Route path="/sitios" exact component={Sitios} />
						<Route path="/sitios/agregar" exact component={SitiosAgregar} />
						<Route path="/fichas_tecnicas" exact component={FichasTecnicas} />
						<Route path="/fichas_tecnicas/agregar" exact component={FichasTecnicasAgregar} />
						<Route path="/fichas_tecnicas/editar/:id_especimen" exact component={FichasTecnicasEditar} />
						<Route path="/atractivo" exact component={Atractivo} />
						<Route path="/atractivo/agregar" exact component={AtractivoEditar} />
						<Route path="/atractivo/editar/:id_atractivo" exact component={AtractivoEditar} />
						<Route path="/app360" exact component={App360} />
						<Route path="/aquanick" render={(props) => {
							return (
								<Switch>
									<Route path={`${props.match.path}/sales`} component={AquanickSales}/>
								</Switch>
							)
						}}/>
					</SideBar>
				</Switch>
			</Router>
		</Provider>
	)
};

export default Routes;