import React, { useEffect, useState } from 'react';

import ImagenUpload from '../UploadFile/Imagen';

const Atractivo = ({atractivo, setData, setFiles}) => {
    const [nombre, setNombre] = useState('');
	const [ubicacion, setUbicacion] = useState('');
	const [logotipo, setLogotipo] = useState({file: null, uri: "https://static.thenounproject.com/png/558475-200.png"});
	const [horarios, setHorarios] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [recomendaciones, setRecomendaciones] = useState('');
	const [imagen, setImagen] = useState({file: null, uri: "https://static.thenounproject.com/png/558475-200.png"});

    useEffect(() => {
		if (atractivo) {
			setNombre(atractivo.nombre);
			setUbicacion(atractivo.ubicacion);
			setHorarios(atractivo.horarios);
			setRecomendaciones(atractivo.recomendaciones);
			setLogotipo({file: null, uri: atractivo.logotipo || 'https://static.thenounproject.com/png/558475-200.png'});
			setImagen({file: null, uri: atractivo.imagen || 'https://static.thenounproject.com/png/558475-200.png'});
		}
    }, [atractivo])

    useEffect(() => {
        let data = {...atractivo, nombre, ubicacion, horarios, descripcion, recomendaciones};
        let files = [];

		if (logotipo.file) {
			files.push({name: 'logotipo', file: logotipo.file});
		}

		if (imagen.file) {
			files.push({name: 'imagen', file: imagen.file});
		}

        setData(data);
        setFiles(files);

    },[nombre, ubicacion, logotipo, horarios, descripcion, recomendaciones, imagen])

    return (
        <>
            {/**Nombre Atractivo */}
            <section className="column">
                <div className="input-content">
                    <label>Nombre completo del lugar:</label>
                    <input
                        name="nombre_atractivo"
                        value={nombre}
                        onChange={(input) => setNombre(input.target.value)}
                        className="input input-text full"></input>
                </div>
            </section>

			{/**Ubicacion del lugar */}
			<section className="column">
                <div className="input-content">
                    <label>Ubicación del lugar:</label>
                    <input
                        name="ubicacion_atractivo"
                        value={ubicacion}
                        onChange={(input) => setUbicacion(input.target.value)}
                        className="input input-text full"></input>
                </div>
            </section>

            {/**Logotipo */}
            <section>
                <div className="input-content full">
                    <label>Logotipo del atractivo turistico:</label>
                    <ImagenUpload
                        id		=	"atractivo-logotipo"
                        setFile	= 	{(imagen) => setLogotipo(imagen)}
                        imagen 	= 	{logotipo}/>
                </div>
            </section>

			{/**Horarios */}
			<section className="column">
                <div className="input-content">
                    <label>Horarios y días de operación:</label>
                    <input
                        name="horarios_atractivo"
                        value={horarios}
                        onChange={(input) => setHorarios(input.target.value)}
                        className="input input-text full"></input>
                </div>
            </section>

			{/**Descripcion */}
			<section className="column">
                <div className="input-content column">
                    <label>Descripción del atractivo e historia:</label>
                    <div className="white-space-16"></div>
                    <textarea
						rows="10"
                        name="descripcion_atractivo"
                        value={descripcion}
                        onChange={(input) => setDescripcion(input.target.value)}
                        className="input input-text full"></textarea>
                </div>
            </section>

			{/**Recomendaciones */}
			<section className="column">
                <div className="input-content column">
                    <label>Recomendaciones de actividades dentro del atractivo Tips.:</label>
                    <div className="white-space-16"></div>
                    <textarea
						rows="10"
                        name="recomendaciones_atractivo"
                        value={recomendaciones}
                        onChange={(input) => setRecomendaciones(input.target.value)}
                        className="input input-text full"></textarea>
                </div>
            </section>

			{/**Logotipo */}
			<section>
                <div className="input-content column">
                    <label>Imagen Ilustrativa:</label>
                    <div className="white-space-16"></div>
                    <div className="row full alig-center justify-center">
                    <ImagenUpload
                        id		=	"imagen-atractivo"
                        setFile	= 	{(imagen) => setImagen(imagen)}
                        imagen 	= 	{imagen}/>
                    </div>
                </div>
            </section>

            <button type="submit" className="btn">
                Guardar
            </button>
        </>
    )
}

export default Atractivo;
