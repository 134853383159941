import React, { useEffect, useState } from 'react';

import './styles.scss';

const UploadAudio = ({id, setFile, audio}) => {
    const [current_audio, setCurrent_audio] = useState({file: null, uri: ''});
    const [audioSound, setAudioSound] = useState(null);

    useEffect(() => {
        if (audio) {
            setCurrent_audio(audio);
        }
    }, [audio]);

    useEffect(() => {
        if (audio) {
            setAudioSound(new Audio(current_audio.uri));
        }
    }, [current_audio])

    function handleChange(input) {
        const files = input.files;
        if (files[0]) {
            let file = files[0];
            const reader = new FileReader();

            reader.onload = () => {
                if(setFile) {
                    setFile({file, uri: reader.result});
                }
            };

            reader.readAsDataURL(file);
        }
    }

    function playSound() {
        if(current_audio.uri) {
            audioSound.play();
        }
    }

    function stopSound() {
        if(current_audio.uri) {
            audioSound.pause();
            audioSound.currentTime = 0;
        }
    }

    if (!id) {
        return null;
    }

    return (
        <label className="audio-upload align-center" htmlFor={id}>
            
            <img className="img-audio" src="https://images.squarespace-cdn.com/content/v1/5572b7b4e4b0a20071d407d4/1487950940401-8I98SQV1AFDBYFBG2IAU/Whitelisting+Services" alt="audio"></img>
            <input
                id={id}
                type="file"
                name="fileUpload"
                accept="audio/mp3"
                onChange={(event) => handleChange(event.target)}/>
            
            <button className="btn row align-center justify-center" onClick={() => playSound()} type="button">
                <i className="fas fa-play">&nbsp;</i>PLAY
            </button>
            <button className="btn row align-center justify-center" onClick={() => stopSound()} type="button">
                <i className="fas fa-stop"></i>&nbsp;STOP
            </button>
        </label>
    )
}

export default UploadAudio;