import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CogoToast from 'cogo-toast';

import Request from '../../core/http';

import FormEspecimen from '../../components/Forms/Ficha_Tecnica';

const Editar = () => {
	const [especimen, setEspecimen] = useState({});
	const [data, setData] = useState({});
	const [files, setFiles] = useState([]);

	const history = useHistory();
	const params = useParams();

	useEffect(() => {
		loadInfo(params.id_especimen);
	}, [params])



	async function loadInfo(id_especimen) {
		if (id_especimen) {
			const response = await Request.get(`/especimenes/${params.id_especimen}`);
			if (response) {
				setEspecimen(response);
			}
		}
	}

	async function submit(event) {
		event.preventDefault();

		const response = await Request.post('/especimenes/editar', data, files);

		if (response.error) {
			CogoToast.error(response.message || 'Error interno')
		}

		if (response.edited) {
			history.goBack();
		}

	}

	return (
		<div className="editar">
			<div className="navigation">
				<button className="btn" onClick={() => history.goBack()}>
					volver
				</button>
			</div>
			<form id="ingreso_informacion" onSubmit={(event) => submit(event)}>
				<h1>Ingreso de información</h1>
				<div className="white-space-24"></div>
				<FormEspecimen
					especimen	= {especimen}
					setData		= {(data) => setData(data)}
					setFiles	= {(files) => setFiles(files)}/>
			</form>
		</div>
	)
}

export default Editar;