import React, { useEffect, useState } from 'react';

import './styles.scss';

const UploadVideo = ({id, setFile, video}) => {
    const [current_video, setCurrent_Video] = useState({file: null, uri: ''});

    useEffect(() => {
        if (video.uri !== current_video.uri) {
            setCurrent_Video({file: null, uri: video.uri});
        }
    }, [video])

    function handleChange(input) {
        const files = input.files;
        if (files[0]) {
            let file = files[0];
            const reader = new FileReader();

			setFile({file, uri: ''});

            reader.onload = () => {
                if(setFile) {
                    setFile({file, uri: reader.result});
                }
            };

            reader.readAsDataURL(file);
        }
    }

    if (!id) {
        return null;
    }

    return (
		<div className="column full">
			<label className="video-upload align-center justify-center full" htmlFor={id}>
				<i className="fas fa-upload"></i>
				<input
					id={id}
					type="file"
					name="fileUpload"
					accept="video/mp4"
					onChange={(event) => handleChange(event.target)}/>
			</label>
			{current_video.uri &&
				<video width="100%" height="100%" controls>
					<source src={current_video.uri} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			}
		</div>
    )
}

export default UploadVideo;