import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CogoToast from 'cogo-toast';

import App360Form from '../../components/Forms/App360';
import mobileImage from './mobileImage.jpg';

const App360 = () => {
    const [data, setData] = useState({});
	const [files, setFiles] = useState([]);

	const history = useHistory();

    async function submit(event) {
		event.preventDefault();

		const response = await Request.post('/especimenes/crear', data, files);

		if (response.error) {
			CogoToast.error(response.message || 'Error interno')
		}

		if (response.created) {
			CogoToast.success(response.message || 'Registro creado correctamente');
			history.goBack();
		}

	}

    return (
        <div className="agregar">
            <div className="navigation">
				<button className="btn" onClick={() => history.goBack()}>
					volver
				</button>
			</div>
            <form id="ingreso_informacion" onSubmit={(event) => submit(event)}>
				<h1>Plantilla para video 360</h1>
				<div className="white-space-24"></div>
				<App360Form
					setData		= {(data) => setData(data)}
					setFiles	= {(files) => setFiles(files)}
                    mobileImage = {mobileImage}/>
			</form>
        </div>
    )
}

export default App360;
