import React from 'react';

//Components
import CardLogin from '../../components/CardLogin';

//Styles
import './login.scss'

const Login = () => {
	return (
		<div className="login">
			<div className="row full login">
				<div className="column logo-column full">
					<div className="overlay-login"></div>
				</div>
				<div className="column full form-column justify-center align-center">
					<CardLogin></CardLogin>
				</div>
			</div>
		</div>
	)
}

export default Login;