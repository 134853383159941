import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';

import { Consumer } from '../context/';

import Request from '../core/http';
import Spinner from './Spinner';
import Logo from '../pages/login/logo.jpg';

const CardLogin = ({context}) => {

	const [loading, setLoading] = useState(false);

	const history = useHistory();

	async function submit(event) {
        event.preventDefault();

        setLoading(true);

        const form = event.target;
        const data = {
            usuario: form.usuario.value || '',
            password: form.password.value || ''
        }

        const response = await Request.post('/usuarios/login/', data);

        if (response.error) {

			setLoading(false);
            cogoToast.error(response.message, { position: 'top-right', heading: 'OOPS!' });

        } else {
            if(response.token) {
				context.loginSuccess({ auth: true, token: response.token });
				setLoading(false);
                history.push("/home");
            } else {
				setLoading(false);
				cogoToast.error(response.message || 'Usuario o contraseña incorrectos', { position: 'top-right', heading: 'OOPS!' });
			}
        }
    }

	return (
		<form className="column justify-center align-center full" onSubmit={submit.bind(this)}>
			<img className="logo-img" src={Logo}></img>
			<div className="row full label-form align-center justify-start">
				<i className="fas fa-user-circle font-small color-gmv"></i> 
				<p className="font-small weight-tiny color-gmv">&nbsp;&nbsp;Usuario</p>
			</div>
			<input
				className="input-form-login"
				type="text"
				id="usuario"
				name="usuario"
				placeholder=""
				maxLength="256"
				required>
			</input>
			<div className="white-space-8"></div>
			<div className="row full label-form align-center justify-start">
				<i className="fas fa-lock font-small color-gmv"></i> 
				<p className="font-small weight-tiny color-gmv">&nbsp;&nbsp;Contraseña</p>
			</div>
			<input
				className="input-form-login"
				type="password"
				id="password"
				name="password"
				placeholder=""
				maxLength="256"
				required>
			</input>  
			
			<div className="white-space-48"></div>
			<div className="row full justify-center">
				<button className="btn-login color-blanco" type="submit" disabled={loading}>
					{
						loading ?
						<Spinner name="ball-beat" color="aqua" fadeIn="none"/> : <span>Ingresa con usuario </span>
					}
				</button>
			</div>
		</form>
	);
}

export default Consumer(CardLogin);