import React, { useState, useEffect } from 'react';

const ImageModalView = ({ open, imagen, onClose }) => {
	const [isOpen, setIsOpen] = useState(false);

	function closeModal() {
		setIsOpen(false);
		if (onClose) {
			onClose();
		}
	}

	useEffect(() => {
		setIsOpen(open);
	}, [open])

	return (
		<div className={isOpen? 'modal open' : 'modal hidden'} id="imageModalView">
			<div className="modal-content" id="imageModalViewCaption">
				<div className="close" onClick={() => closeModal()}>
					<i className="fa fa-times"></i>
				</div>
				<div className="modal-body">
					<div className="image-content">
						{imagen ? <img src={imagen} alt="ImageModalView"/> : null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageModalView;
