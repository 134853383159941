import { useHistory } from 'react-router-dom';

import Tabla from './tabla';

const Sales = () => {

    const history = useHistory();

    return (
        <div className="aquanick page">
            <div className="row full justify-between">
			    <h1>AquaNick</h1>
                <div className="controles">
                </div>
			</div>
			<div className="white-space-32"></div>
			<Tabla/>
        </div>
    )
}

export default Sales;
