import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CogoToast from 'cogo-toast';

import Request from '../../core/http';

import FormEspecimen from '../../components/Forms/Ficha_Tecnica';

const Agregar = () => {

	const [data, setData] = useState({});
	const [files, setFiles] = useState([]);

	const history = useHistory();

	async function submit(event) {
		event.preventDefault();

		const response = await Request.post('/sitios/crear', data, files);

		if (response.error) {
			CogoToast.error(response.message || 'Error interno')
		}

		if (response.created) {
			CogoToast.success(response.message || 'Registro creado correctamente');
			history.goBack();
		}

	}

	function setProfileImg(event) {
		const files = event.target.files || event.dataTransfer.files;
		if (files[0]) {
			
		}
	}

	return (
		<div className="agregar">
			<div className="navigation">
				<button className="btn" onClick={() => history.goBack()}>
					volver
				</button>
			</div>
			<form id="ingreso_informacion" onSubmit={(event) => submit(event)}>
				<h1>Agrega un nuevo Sitio</h1>
				<div className="white-space-24"></div>
				<form onSubmit={(event) => submit(event)}>

        </form>
			</form>
		</div>
	)
}

export default Agregar;