import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ImageModalView from '../../components/ImageModalView';

import Request from '../../core/http';

const Tabla = () => {
	const [filas, setFilas] = useState([]);
	const [viewImage, setViewImage] = useState('');
	const [isOpenViewImage, setIsOpenViewImage] = useState(false);

	useEffect(() => {
		loadTable();
	}, [])

	async function loadTable() {

		const response = await Request.get('/sitios');

		if (Array.isArray(response)) {
			setFilas(response);
		} else {
			setFilas([]);
		}
	}

	return (
		<div className="row table-container">
			<table id="tabla-especimenes">
				<thead>
					<tr>
						<th className="text-left">Sitio</th>
            <th className="text-left"></th>
            <th className="text-left">URL</th>
            <th className="text-left"></th>
					</tr>
				</thead>
				<tbody>
					{filas.map((fila) => 
						<tr key={fila.id_especimen}>
							<td>
								{fila.nombre}
							</td>
							<td>
								<div className="thumbnail">
									<img className="pointer" src={fila.foto_perfil} alt="perfil"
										onClick={() => {
											setViewImage(fila.foto_perfil);
											setIsOpenViewImage(true);
										}}/>
								</div>
							</td>
              <td>
								<a href={fila.url}>{fila.url}</a>
							</td>
							<td>
								<Link className="table-link" to={`/sitios/editar/${fila.id_especimen}`}>
									<i className="fa fa-pencil"></i>
								</Link>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<ImageModalView
				open	=	{isOpenViewImage}
				imagen	=	{viewImage}
				onClose	=	{() => {
					setIsOpenViewImage(false);
					setViewImage('');
				}}/>
		</div>
	)
}

export default Tabla;
