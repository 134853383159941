import React, { useEffect, useState } from 'react';
import './styles.scss';

const UploadImagen = ({id, setFile, imagen}) => {
    const [current_image, setCurrent_Image] = useState({file: null, uri: 'https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png'});

    useEffect(() => {
        if (imagen.uri !== current_image.uri) {
            setCurrent_Image({file: null, uri: imagen.uri || 'https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png'});
        }
    }, [imagen])

    function handleChange(input) {
        const files = input.files;
        if (files[0]) {
            let file = files[0];
            const reader = new FileReader();

            reader.onload = () => {
                if(setFile) {
                    setFile({file, uri: reader.result});
                }
            };

            reader.readAsDataURL(file);
        }
    }

    if (!id) {
        return null;
    }

    return (
        <label className="imagen-upload " htmlFor={id}>
            <img src={current_image.uri} alt="profile"></img>
            <input
                id={id}
                type="file"
                name="fileUpload"
                accept="image/*"
                onChange={(event) => handleChange(event.target)}/>
        </label>
    )
}

export default UploadImagen;