import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Consumer } from '../../context';

import Tabla from './tabla';

//Styles
const SitesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Sitios = ({context}) => {

  const history = useHistory();

	return (
		<SitesContainer>
			<h1>Sitios</h1>
      <div className="controles">
				<button className="btn" onClick={() => history.push('/sitios/agregar')}>
				 <i className="fas fa-plus"></i>&nbsp;Agregar 
				</button>
			</div>
      <div className="white-space-32"></div>
			<Tabla/>
		</SitesContainer>
	)	
}

export default Consumer(Sitios);