import React, { useEffect, useState } from 'react';

import ImagenUpload from '../UploadFile/Imagen';
import AudioUpload from '../UploadFile/Audio';
import VideoUpload from '../UploadFile/Video';

const basicos =  { titulo: '', dato: ''};

const Ficha_Tecnica = ({especimen, setData, setFiles}) => {
    const [nombre, setNombre] = useState('');
	const [profileImage, setProfileImage] = useState({file: null, uri: "https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png"});
    const [audioInformacion, setAudioInformacion] = useState({file: null, uri: ''});
	const [basicoA, setBasicoA] = useState(basicos);
	const [basicoB, setBasicoB] = useState(basicos);
	const [basicoC, setBasicoC] = useState(basicos);
	const [datosInteresantes, setDatosInteresantes] = useState([]);
	const [imagenes, setImagenes] = useState([]);
	const [videos, setVideos] = useState([]);
	const [audios, setAudios] = useState([]);

    useEffect(() => {
		let datos = [];

		for(let di = 0; di <= 9; di++) {
			datos.push({ id: (di + 1), titulo: '', icono: {file: null, uri: ''}, detalle: ''});
		}

		setDatosInteresantes(datos);

	}, []);

    useEffect(() => {
		if (especimen) {
			setNombre(especimen.nombre);
			setProfileImage({file: null, uri: especimen.foto_perfil || 'https://img.icons8.com/officel/2x/add-image.png'});
			setAudioInformacion({file: null, uri: especimen.audio_informacion || ''});

			let datosBasicos = especimen.datos_basicos,
			dInteresantes = especimen.datos_interesantes,
			iIlustrativas = especimen.imagenes_ilustrativas,
			vTematicos = especimen.videos_tematicos,
			aComplementarios = especimen.audios_complementarios;

			if(especimen.datos_basicos) {
				if (datosBasicos.basicoA) {
					setBasicoA(datosBasicos.basicoA);
				}
				if (datosBasicos.basicoB) {
					setBasicoB(datosBasicos.basicoB);
				}
				if (datosBasicos.basicoC) {
					setBasicoC(datosBasicos.basicoC);
				}
			}

			if (dInteresantes) {
				if(Array.isArray(dInteresantes)) {
					dInteresantes = dInteresantes.map(d => {
						if(d.icono) {
							if (d.icono.uri) {
								d.icono = {file: null, uri: d.icono.uri}
							} else {
								d.icono = {file: null, uri: d.icono}
							}
						} else {
							d.icono = {file: null, uri: ''}
						}
						return d;
					});

					setDatosInteresantes(dInteresantes);
				}
			}

			if(iIlustrativas) {
				if(Array.isArray(iIlustrativas)) {
					iIlustrativas = iIlustrativas.map(i => {
						if(i) {
							i = { file: null, uri: i };
						}
						return i;
					}).filter(i => i);

					setImagenes(iIlustrativas);
				}
			}

			if(vTematicos) {
				if(Array.isArray(vTematicos)) {
					vTematicos = vTematicos.map(v => {
						if(v) {
							v = { file: null, uri: v }
						}
						return v;
					}).filter(v => v);

					setVideos(vTematicos);
				}
			}

			if(aComplementarios) {
				if(Array.isArray(aComplementarios)) {
					aComplementarios = aComplementarios.map(a => {
						if(a) {
							a = { file: null, uri: a };
						}
						return a;
					}).filter(a => a);

					setAudios(aComplementarios);
				}
			}
		}
    }, [especimen])

    useEffect(() => {
        const datos_basicos = JSON.stringify({
            basicoA,
            basicoB,
            basicoC
        });

        let data = {...especimen, nombre, datos_basicos};
        let files = [];

		if (profileImage.file) {
			files.push({name: 'foto_perfil', file: profileImage.file});
		}

		if(audioInformacion.file) {
			files.push({name: 'audio_informacion', file: audioInformacion.file});
		}

        if(datosInteresantes) {
            let datos_interesantes = [];
            for(let index in datosInteresantes) {
                const dato = datosInteresantes[index];

                datos_interesantes.push({
					id: dato.id,
					titulo: dato.titulo,
					icono: dato.icono ? dato.icono.uri : undefined,
					dato: dato.dato
				});

                if(dato.icono.file) {
                    files.push({name: `di_icono_${dato.id}`, file: dato.icono.file})
                }
            }

            data = {...data, datos_interesantes: JSON.stringify(datos_interesantes)};
        }

		if(imagenes.length > 0) {
			let imagenes_ilustrativas = [];
			imagenes.forEach((imagen, index) => {
				if(imagen.file) {
					imagenes_ilustrativas.push(`imagen_ilustrativa_${index}`);
					files.push({name: `imagen_ilustrativa_${index}`, file: imagen.file});
				} else if (imagen.uri.indexOf('http') !== -1) {
					imagenes_ilustrativas.push(imagen.uri);
				}
			});

			data = {...data, imagenes_ilustrativas};
		}

		if(videos.length > 0) {
			let videos_tematicos = [];
			videos.forEach((video, index) => {
				if(video.file) {
					videos_tematicos.push(`video_tematico_${index}`);
					files.push({name: `video_tematico_${index}`, file: video.file});
				} else if (video.uri.indexOf('http') !== -1) {
					videos_tematicos.push(video.uri);
				}
			});

			data = {...data, videos_tematicos};
		}

		if(audios.length > 0) {
			let audios_complementarios = [];
			audios.forEach((audio, index) => {
				if(audio.file) {
					audios_complementarios.push(`audio_complementario_${index}`);
					files.push({name: `audio_complementario_${index}`, file: audio.file});
				} else if (audio.uri.indexOf('http') !== -1) {
					audios_complementarios.push(audio.uri);
				}
			});

			data = {...data, audios_complementarios};
		}

        setData(data);
        setFiles(files);

    },[nombre, profileImage, basicoA, basicoB, basicoC, audioInformacion, datosInteresantes, imagenes, videos, audios])

    return (
        <>

            <section className="column banner-form">
                <div className="row full">
                    <div className="column img-column">
                        <ImagenUpload
                            id		=	"especimen-foto"
                            setFile	= 	{(imagen) => setProfileImage(imagen)}
                            imagen 	= 	{profileImage}/>
                    </div>
                   <div className="column full justify-end">
                        <h1 className="color-white uppercase">{nombre}</h1>
                   </div>
                </div>
            </section>
        

            <div className="white-space-16"></div>
            <h2>Basicos/Generales</h2>
            <div className="white-space-24"></div>

            {/**Nombre Especimen */}
            <section className="column">
                <div className="row full">
                    <div className="input-content full align-center">
                    <label>Nombre:</label>
                    <input
                        name="nombre_especimen"
                        value={nombre || ''}
                        onChange={(input) => setNombre(input.target.value)}
                        className="input input-text full"></input>
                </div>
                </div>
            </section>
			{/**Datos generales */}
            <section className="column">
				

                <div className="input-content">
                    <label>DatoA:</label>
                    <input
                        name="basico_uno_titulo"
                        placeholder="titulo"
                        value={basicoA.titulo || ''}
                        onChange={(input) => setBasicoA({...basicoA, titulo: input.target.value})}
                        className="input input-text input-small"></input>
                    <input
                        name="basico_uno_dato"
                        placeholder="dato"
                        value={basicoA.dato}
                        onChange={(input) => setBasicoA({...basicoA, dato: input.target.value})}
                        className="input input-text"></input>
                </div>
                <div className="input-content">
                    <label>DatoB:</label>
                    <input
                        name="basico_dos_titulo"
                        placeholder="titulo"
                        value={basicoB.titulo}
                        onChange={(input) => setBasicoB({...basicoB, titulo: input.target.value})}
                        className="input input-text input-small"></input>
                    <input
                        name="basico_dos_dato"
                        placeholder="dato"
                        value={basicoB.dato}
                        onChange={(input) => setBasicoB({...basicoB, dato: input.target.value})}
                        className="input input-text"></input>
                </div>
                <div className="input-content">
                    <label>DatoC:</label>
                    <input
                        name="basico_tres_titulo"
                        placeholder="titulo"
                        value={basicoC.titulo}
                        onChange={(input) => setBasicoC({...basicoC, titulo: input.target.value})}
                        className="input input-text input-small"></input>
                    <input
                        name="basico_tres_dato"
                        placeholder="dato"
                        value={basicoC.dato}
                        onChange={(input) => setBasicoC({...basicoC, dato: input.target.value})}
                        className="input input-text"></input>
                </div>
            </section>

            <section>
                <div className="input-content full">
                    <label>Audio de información:</label>
                    <AudioUpload
                        id		=	"especimen-audio"
                        setFile	= 	{(audio) => setAudioInformacion(audio)}
                        audio 	= 	{audioInformacion}/>
                </div>
            </section>

            <div className="white-space-16"></div>
            <h2>Datos interesantes</h2>
            <div className="white-space-24"></div>
			
            <section className="column">
                {datosInteresantes.map((datoInt, index) => {
					return(
						<div className="input-content datos-interesantes" key={datoInt.id}>
							<label>Icono {index + 1}:</label>
							<ImagenUpload
								id		=	{`icono-dato-${index + 1}`}
								setFile	= 	{(icono) => {
									const datos = [...datosInteresantes];
									datos[index].icono = icono;
									setDatosInteresantes(datos);
								}}
								imagen 	= 	{datoInt.icono}/>
							<label>Dato {index + 1}:</label>
							<input
								name="dato_interesante_titulo"
								placeholder="titulo"
								className="input input-text"
								value={datoInt.titulo || ''}
								onChange={(event) => {
									const datos = [...datosInteresantes];
									datos[index].titulo = event.target.value;
									setDatosInteresantes(datos);
								}}/>
							<input
								name="dato_interesante"
								placeholder="dato"
								className="input input-text"
								value={datoInt.dato || ''}
								onChange={(event) => {
									const datos = [...datosInteresantes];
									datos[index].dato = event.target.value;
									setDatosInteresantes(datos);
								}}/>
						</div>
					)
				})}
            </section>

            <div className="white-space-16"></div>
            <div className="row full justify-between align-center"> 
                <div className="column">
                    <h2>Imagenes ilustrativas</h2>
                </div>
                <div className="column">
                <button className="btn btn-ok align-center justify-center" type="button" onClick={() => {
					let imgs = [...imagenes] || [];
					imgs.push({uri: ''});
					setImagenes(imgs)
				}}>
                    <i className="fas fa-plus">&nbsp;</i>Agregar Imagen
                </button>
                </div>
            </div>
            
            <div className="white-space-24"></div>

            <section id="imagenes-ilustrativas" className="row full">
				
				<div className="overflow">
					{imagenes.map((imgIlustrativa, index) =>
						<div className="image-content" key={index}>
							<ImagenUpload
								id		=	{`imagen-ilustrativa-${index + 1}`}
								setFile	= 	{(imagen) => {
									let imgs = [...imagenes] || [];
									imgs[index] = imagen;
									setImagenes(imgs);
								}}
								imagen 	= 	{imgIlustrativa}/>
						</div>
					)}
				</div>
            </section>

            
            <div className="white-space-16"></div>
            <h2>Videos temáticos</h2>
            <div className="white-space-24"></div>
            
            <section className="row full">
                {videos.map((video, index) =>
                    <div className="video-content" key={index}>
						<VideoUpload
							id		=	{`video-${index + 1}`}
							setFile	= 	{(video) => {
								let vids = [...videos] || [];
								vids[index] = video;
								setVideos(vids);
							}}
							video 	= 	{video}/>
                    </div>
                )}
            </section>

            <div className="white-space-16"></div>
            <h2>Audios Complementario</h2>
            <div className="white-space-24"></div>

            <section className="row full">
                {audios.map((audioComp, index) =>
                    <div className="audio-content" key={index}>
                        <AudioUpload
                            id		=	{`audio-complemenatario-${index + 1}`}
                            setFile	= 	{(audio) => {
								let auds = [...audios] || [];
								auds[index] = audio;
								setAudios(auds)
							}}
                            audio 	= 	{audioComp}/>
                    </div>
                )}
            </section>
            
            <div className="white-space-32"></div>
            
            <button type="submit" className="btn btn-ok full">
                Guardar
            </button>
        </>
    )
}

export default Ficha_Tecnica;
