import React from 'react';
import { useHistory } from 'react-router-dom';

import { Consumer } from '../../context';

import Tabla from './tabla';

//Styles
import './fichas_tecnicas.scss';

const Home = ({context}) => {

	const history = useHistory();

	return (
		<div className="fichas_tecnicas page">
			<div className="row full justify-between">
			<h1>Fichas Tecnicas</h1>
			<div className="controles">
				<button className="btn" onClick={() => history.push('/fichas_tecnicas/agregar')}>
				 <i className="fas fa-plus"></i>&nbsp;Agregar 
				</button>
			</div>
			</div>
			<div className="white-space-32"></div>
			<Tabla/>
		</div>
	)	
}

export default Consumer(Home);