import React, { useEffect, useState } from 'react';
import { Consumer } from '../context';
import { NavLink, useHistory } from "react-router-dom";
import  IMG_URL  from '../core/Logo.png';

const SideBar = ({ context, children }) => {

	const [nombre, setNombre] = useState('App Gimav');
	const [imagen, setImagen] = useState('');
	const [modulos, setModulos] = useState([]);

	let history = useHistory();

	useEffect(() => {
		setModulos(context.modulos);
	}, [context.modulos])

    function logout() {
        context.logout(history);
    }    

    function opensidebar() {
        const bar = document.getElementById("sidebar-mobile");
		if(bar.classList.contains('open')) {
			bar.classList.remove('open');
		}else {
            bar.classList.add('open');
        }
    }

    function imgError(img) {
		img.target.src = 'https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png'; //"/images/user-back.png";
	}

	return (
		<div className="row full view dashboard">
			<div className="btn-column btn-menu column">
				<i className="fas fa-bars color-white btn-sidebar " onClick={opensidebar.bind(this)}></i>
			</div>
			<div className="column side-bar" id="sidebar-mobile" onClick={opensidebar.bind(this)}>
				<i className="fas fa-times close-btn color-white"></i>
				<div className="row full profile-row justify-center align-center">
					<div className="column justify-center align-center">
						<img className="profile-img" src={IMG_URL}/>
					</div>
					{/*<div className="column profile-data">
						<p className="weight-bold">{nombre}</p>
						<p className="weight-regular"></p>
	</div>*/}
				</div>
				<div className="white-space-32"></div>
				<div className="row nav-item full justify-start align-center">
					<NavLink
						to={`/home`}
						className="font-regular color-white align-center">
						<i className="fas fa-tachometer-alt"></i>
						<span className="color-white weight-light">Inicio</span>
					</NavLink>
				</div>
				{modulos.map(m =>
					<div className="row nav-item full justify-start align-center" key={m.id_modulo}>
						<NavLink
							to={m.ruta}
							className="font-regular color-white align-center">
							<i className={`${m.icono}`}></i>
							<span className="color-white weight-light">{m.modulo}</span>
						</NavLink>
					</div>
				)}
				<div className="row nav-item full justify-start align-center">
					<a
						onClick={logout.bind(this)}
						className="font-regular color-white align-center">
						<i className="fas fa-sign-out-alt"></i>
						<span className="color-white weight-light">Cerrar sesión</span>
					</a>
				</div>
			</div>
			<div className="column full align-center children-view">
				{children}
			</div>
		</div>
	)
}

export default Consumer(SideBar);